<template>
  <div class="dashboard-editor-container">
    <div class="dashboard-editor-containera">
      <el-container class="el-containers">
        <el-main>
          <div class="imagesbox">
            <el-carousel style="width: 100%; height: 100%">
              <el-carousel-item
                v-for="item in imagesbox"
                :key="item.id"
                style="height: 100%"
              >
                <!-- <font
                  style="
                    position: absolute;
                    bottom: 21px;
                    left: 15px;
                    display: inline-block;
                    letter-space: 1px;
                  "
                  color="#cccccc"
                  >{{ item.info }}</font
                > -->
                <img
                  :src="item.idView"
                  class="image"
                  style="width: 100%; height: 100%"
                  @click="$router.push('/articleDetail?id='+item.info)"
                />
              </el-carousel-item>
            </el-carousel>
          </div>

          <!-- <div class="purpleaa">
          <img class="img" :src="url" />
          <img class="img" :src="url_1" />
          </div>-->
        </el-main>
        <div style="width: 22%; height: 100%; margin-left: 20px">
          <div style="position: relative; height: 100%" class="kuaixun">
            <div class="radio">
              <div class="radio_left">最新快讯</div>
              <span @click="rotateFlash()">
                <img
                  src="@/assets/icon/图层 33@2x.png"
                  style="width: 22px"
                  id="flash-bin"
                />
              </span>
            </div>

            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                class="kx"
                :timestamp="activity.timestamp"
                placement="top"
                @click.native="activitys(activity.id, activity.hotNum)"
                style="cursor: pointer; line-height: 15px"
              >
                <span class="sheng_luehao">{{ activity.title }}</span>
              </el-timeline-item>
            </el-timeline>
            <div style="position: absolute; right: 13px; bottom: 7px">
              <span class="more" style="cursor: pointer">
                <router-link to="newsFlash">更多 >></router-link>
              </span>
            </div>
          </div>
        </div>
      </el-container>
    </div>

    <!-- 精选文章 精选主题 -->
    <div class="center">
      <el-container>
        <el-main>
          <div class="list">
            <div class="grid-content">最新文章</div>
            <V-ArticleList :newarticlelist="newarticlelist"></V-ArticleList>
          </div>
        </el-main>

        <el-aside style="width: 22%">
          <div class="Jxwz">
            <span id="jinxuan1">精选文章</span>
          </div>
          <div class="aside_t">
            <ul class="aside_t_t">
              <!-- 精选文章的下方列表 top 1 2-->
              <li v-for="(item, index) in Selected" :key="index">
                <div @click="gotoArticleDetail(item.id, item.hotNum)">
                  <el-image :src="item.url"></el-image>
                </div>
                <p @click="gotoArticleDetail(item.id, item.hotNum)">
                  {{ item.title }}
                </p>
              </li>
            </ul>
            <ul class="aside_t_b">
              <!-- 精选文章的下方列表 -->
              <li
                class="plcont"
                v-for="(item, index) in news"
                :key="index"
                @click="gotoArticleDetail(item.id, item.hotNum)"
              >
                <div class="ad"></div>
                <span>{{ item.title }}</span>
              </li>
            </ul>
          </div>

          <!-- <el-row :gutter="10" class="aside_center"> -->
          <div class="Jxwz">
            <span id="jinxuan1">精选主题</span>
            <!-- <span id="jinxuan2">MORE +</span> -->
          </div>
          <!-- </el-row> -->
          <div class="aside_b">
            <ul class="aside_b_center">
              <li
                class="plcont xjwplcont"
                v-for="(item, index) in SThemes"
                :key="index"
                @click="gotoDetail(item.id)"
              >
                <div>
                  <span class="lines-ellipsis" style="width: 50%">{{
                    item.content
                  }}</span>
                  <img :src="item.url" />
                </div>
              </li>
            </ul>
          </div>
        </el-aside>
      </el-container>
    </div>
    <!-- 下载 -->
    <div class="download">
      <span href="javaScript:void(0)" class="select-btn">
        下载app
        <!-- 意见反馈 -->
        <!-- <span class="shouji"></span> -->
      </span>
      <div>
        <span @click="help" href="javaScript:void(0)" class="select-btn-grey"
          >指南中心</span
        >
      </div>
      <div class="select-tips">
        <i class="select-tip-arrow">
          <em></em>
        </i>
        <div>
          <div class="fill-content">
            <!-- <div class="fill-d">问题竞价不超过知识币</div> -->
            <span class="dis" style="margin-right: 18px">
              <div class="EWM">
                <img
                  class="EWM"
                  :src="
                    api.LoginURL.concat('/resources/trading/upload/png/ios.png')
                  "
                />
              </div>
              <div style="text-align: center">ios下载</div>
            </span>
            <span class="dis" style="margin-top: 10px">
              <div class="EWM">
                <img
                  class="EWM"
                  :src="
                    api.LoginURL.concat('/resources/trading/upload/png/az.png')
                  "
                />
              </div>
              <div style="text-align: center">安卓下载</div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import gql from "graphql-tag";
import ArticleList from "@/components/one-article/type-one/index.vue";
import { DELETE, QUERY, UPDATES, UPDATE, QUERYED } from "@/services/dao.js";
import {
  showTimeFormat,
  formatDate,
  dataFormat,
  updateHotnumFn,
  integralAddOne,
} from "@/js/common";
import { parse } from "graphql";
export default {
  name: "Home",
  components: { "V-ArticleList": ArticleList },
  data() {
    return {
      XiaZaivisible: false,
      imagesbox: [
        {
          id: 0,
          idView: require("@/assets/u=1367050145,1852793189&fm=26&gp=0.jpg"),
        },
        {
          id: 1,
          idView: require("@/assets/u=1367050145,1852793189&fm=26&gp=0.jpg"),
        },
        {
          id: 2,
          idView: require("@/assets/u=1367050145,1852793189&fm=26&gp=0.jpg"),
        },
        {
          id: 3,
          idView: require("@/assets/u=1367050145,1852793189&fm=26&gp=0.jpg"),
        },
        {
          id: 4,
          idView: require("@/assets/u=1367050145,1852793189&fm=26&gp=0.jpg"),
        },
        {
          id: 5,
          idView: require("@/assets/u=1367050145,1852793189&fm=26&gp=0.jpg"),
        },
        {
          id: 6,
          idView: require("@/assets/u=1367050145,1852793189&fm=26&gp=0.jpg"),
        },
      ],
      url: require("@/assets/img/5(654).png"),
      url_1: require("@/assets/img/d00461e0a25c663718680b788f4f5f74.png"),
      reverse: true,
      newarticlelist: [],
      pageSize: 12,
      news: [],
      SThemes: [],
      Selected: [],
      activities: [],
      // 刷新存储的n
      n: 0,
      showEllipsis: false, // 判断文本是否溢出
    };
  },
  created() {
    this.initCarousel(); //初始化轮播图
    this.init(); //初始化文章列表
    this.newslist(); //最新快讯
    this.STheme(); //精选主题
    this.jinXuan(); //精选文章
  },
  mounted() {
    let e = document.getElementsByClassName("lines-ellipsis");
  },
  methods: {
    //指南中心
    help() {
      this.$router.push({ path: "/academic_help" });
      // window.open('/hmcontent.htm' )
      // loadstate
    },
    pd() {
      this.$apollo
        .query({
          query: q_user,
          variables: {
            id: 1,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    init() {
      this.searchInfo(this.pageSize, 0);
    },
    // 初始化轮播图
    async initCarousel() {
      this.imagesbox = [];
      let info = await QUERYED(
        "post",
        "",
        "TtCarousel(where: {type:{_eq:" +
          0 +
          "}}) {id img content} TtCarousel_aggregate(where:{type:{_eq:" +
          0 +
          "}}) {aggregate { count }} "
      );
      console.log("轮播图: ", info);
      // let count = info.data.TtCarousel_aggregate.aggregate.count;
      // this.total=count;
      for (let i = 0; i < info.data.TtCarousel.length; i++) {
        this.imagesbox.push({
          id: info.data.TtCarousel[i].id,
          idView: this.api.LoginURL.concat(info.data.TtCarousel[i].img),
          info: info.data.TtCarousel[i].content,
        });
      }
    },
    // 初始化文章列表
    async searchInfo(limit, offest) {
      // let info = await QUERY(
      //   "post",
      //   "",
      //   "  Article(limit: " +
      //     7 +
      //     ", offset: " +
      //     0 +
      //     ") {  id    createTime    content    commentNum  coverImg imge  collectNum userid   browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme    title        isChoiceness    isRecommend    hotNum    authorId }  Article_aggregate{    aggregate {      count    }  }"
      // );
      let info = await QUERYED(
        "post",
        "",
        "  Article_copy  {  id    createTime    content    commentNum  coverImg imge  collectNum userid   browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme    title        isChoiceness    isRecommend    hotNum    authorId }  Article_copy_aggregate{    aggregate {      count    }  }"
      );
      this.newarticlelist.splice(0, this.newarticlelist.length);

      for (let i = 0; i < info.data.Article_copy.length; i++) {
        let imgUrla = info.data.Article_copy[i].imge;
        let coverImg = info.data.Article_copy[i].coverImg;
        this.newarticlelist.push({
          title: info.data.Article_copy[i].title,
          intro: info.data.Article_copy[i].intro,
          name: info.data.Article_copy[i].authorName,
          time: formatDate(Date.parse(info.data.Article_copy[i].createTime)),
          collectNum: info.data.Article_copy[i].collectNum,
          userId: 3,
          commentNum: info.data.Article_copy[i].commentNum,
          // image:(info.data.Article[i].coverImg).substring(0,7)=='http://'?info.data.Article[i].coverImg:this.api.LoginURL.concat(info.data.Article[i].coverImg),
          // image: this.api.LoginURL.concat(info.data.Article[i].coverImg),
          image:
            coverImg.indexOf("https://") != -1 ||
            coverImg.indexOf("http://") != -1
              ? coverImg
              : this.api.LoginURL.concat(coverImg),
          id: info.data.Article_copy[i].id,
          hotNum: info.data.Article_copy[i].hotNum,
          praiseNum: info.data.Article_copy[i].praiseNum,
          keyword: info.data.Article_copy[i].keyword,
          userid: info.data.Article_copy[i].userid,
          // imge: this.api.LoginURL.concat(info.data.Article[i].imge)
          imge:
            imgUrla.indexOf("http") > -1
              ? imgUrla
              : this.api.LoginURL.concat(imgUrla),
        });
      }

      this.totlePage = info.data.Article_copy_aggregate.aggregate.count;
    },
    // 最新快讯
    async newslist(limit, offset) {
      let newslist = await QUERY(
        "post",
        "",
        "News_rank_TtArticle_ID(limit:" +
          5 +
          ",offset:" +
          5 * this.n +
          ") {    id    createTime    content        commentNum    collectNum    browseNum    authorName    accessory    img    intro        praiseNum    reference        title        hotNum  }  News_rank_TtArticle_ID_aggregate(where: {}) {    aggregate {      count    }  }"
      );

      // 依次抵消this.n*4条数据，直到最后一条重置this.n=0
      if (
        (this.n + 1) * 5 <
        newslist.data.News_rank_TtArticle_ID_aggregate.aggregate.count
      ) {
        this.n++;
      } else {
        this.n = 0;
      }
      this.activities.splice(0, this.activities.length);

      for (let i = 0; i < newslist.data.News_rank_TtArticle_ID.length; i++) {
        let title = newslist.data.News_rank_TtArticle_ID[i].title;
        let title1 = title;
        if (title.length > 14) title1 = title.substring(0, 14) + "…";
        this.activities.push({
          content: title,
          title: title1,
          // timestamp: showTimeFormat(newslist.data.News[i].createTime),
          timestamp: formatDate(
            Date.parse(newslist.data.News_rank_TtArticle_ID[i].createTime)
          ),
          id: newslist.data.News_rank_TtArticle_ID[i].id,
          hotNum: newslist.data.News_rank_TtArticle_ID[i].hotNum,
        });
      }
    },
    // 最新快讯刷新按钮
    rotateFlash() {
      // js控制刷新图标的动画效果
      let flashIcon = document.getElementById("flash-bin");
      if (!flashIcon.classList.contains("flash-bin")) {
        flashIcon.classList.add("flash-bin");
        setTimeout(() => {
          flashIcon.classList.remove("flash-bin");
        }, 550);
      }
      this.newslist();
    },
    // 精选文章初始化
    async jinXuan() {
      let result = await QUERY(
        "post",
        "",
        "Article(limit: 6, where: {isChoiceness: {_eq: 1}}) { id title img hotNum coverImg content }"
      );
      this.Selected = [];

      for (let i = 0; i < 2; i++) {
        // 替换文本中的html标签及空格
        let html = result.data.Article[i].content;
        html = html.replace(/<\/?.+?>/g, "");
        html = html.replace(/&nbsp;/g, " ");
        this.Selected.push({
          url:
            result.data.Article[i].coverImg.substring(0, 7) == "http://"
              ? result.data.Article[i].coverImg
              : this.api.LoginURL.concat(result.data.Article[i].coverImg),
          // url: this.api.LoginURL.concat(result.data.Article[i].coverImg),
          content: html,
          id: result.data.Article[i].id,
          hotNum: result.data.Article[i].hotNum,
          title: result.data.Article[i].title,
        });
      }
      // 下方4条列表信息
      this.news = [];
      for (let i = 2; i < result.data.Article.length; i++) {
        // 替换文本中的html标签及空格
        let html = result.data.Article[i].content;
        html = html.replace(/<\/?.+?>/g, "");
        html = html.replace(/&nbsp;/g, " ");
        this.news.push({
          title: result.data.Article[i].title,
          id: result.data.Article[i].id,
        });
      }
    }, // 跳转到文章详情
    async gotoArticleDetail(id, hotNum) {
      ///浏览数加1
      hotNum += 1;
      let datdeds = await UPDATES(
        "post",
        "",
        "   update_TtArticle(where: {id: {_eq: " +
          id +
          "}}, _set: {hotNum: " +
          hotNum +
          "}) { affected_rows  }"
      );
      let user = this.$store.state.userinfo.id;
      if (user) {
        integralAddOne(user);
      }
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id,
        },
      });
    },
    async STheme(limit, offset) {
      let info = await QUERY(
        "post",
        "",
        "Theme(limit:  " +
          4 +
          ", offset: " +
          0 +
          ", where: {}) {    id    createTime    content    img    title  }  Theme_aggregate {    aggregate {      count    }  }"
      );
      this.SThemes.splice(0, this.SThemes.length);
      for (let i = 0; i < info.data.Theme.length; i++) {
        this.SThemes.push({
          id: info.data.Theme[i].id,
          url: this.api.LoginURL.concat(info.data.Theme[i].img),
          // content: info.data.Theme[i].title,
          content: info.data.Theme[i].content,
        });
      }
    },
    // 最新快讯的跳转路由
    activitys(id, hotNum) {
      updateHotnumFn(id, hotNum);
      let nextFastAtclId = 1;
      // try {
      //   this.activities.forEach((item) => {
      //     if (item.id != id) {
      //       nextFastAtclId = item.id;
      //       throw new Error("endIterative");
      //     }
      //   });
      // } catch (e) {
      //   if (e.message !== "endIterative") throw e;
      // }
      this.$router.push({
        path: "/newsFlashDetail",
        query: {
          id: id,
          // lowerid: nextFastAtclId,
        },
      });
    },
    // 精选主题的跳转路由
    gotoDetail(id) {
      this.$router.push({
        path: "/themeDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped>
.dashboard-editor-container {
  width: 100%;
  position: relative;
  /* border: 1px solid red; */
}
.dashboard-editor-containera {
  width: 100%;
  height: 330px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  /* border: 1px solid darkblue; */
  display: inline-block;
}
.list {
  margin-bottom: 20px;
}
.center {
  width: 85%;
  margin: auto;
  margin-top: 40px;
  /* background-color: #FFFFFF; */
  /* border: 1px solid red; */
}
.el-main {
  padding: 0;
}
.el-carousel__item img {
  width: 100%;
}
.plcont {
  cursor: pointer;
}
.el-timeline {
  margin-top: 4%;
}
.ad {
  margin-top: 8px;
  width: 6px;
  height: 6px;
  background: rgba(115, 139, 179, 1);
  border: 1px solid rgba(115, 139, 179, 1);
  border-radius: 50%;
  float: left;
}
.el-aside span {
  margin-left: 5px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(110, 113, 129, 1);
  line-height: 22px;
}
.radio_left {
  float: left;
  margin-top: 15px;

  background-color: #1e68bc;
  border-radius: 0px 11px 11px 0px;
  background-size: 100% 100%;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
  padding: 5px 11px 5px 0px;
}
/*最新快讯刷新图标点击动画效果*/
.flash-bin {
  transition: 0.5s;
  transform: rotate(360deg);
  display: inline-block;
}
/*精选文章超出隐藏*/
#jinxuan1 {
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 40px;
}
#jinxuan2 {
  float: right;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 13px;
  color: rgba(120, 120, 120, 1);
  line-height: 40px;
}
.el-aside .aside_t p {
  overflow: hidden;
  height: 21px;
  text-overflow: ellipsis;
  cursor: pointer;
}
.aside_t_b li {
  line-height: 28px;
  overflow: hidden;
  height: 22px;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-top: 10px;
}
.aside_t_t .el-image {
  cursor: pointer;
}
.containersss {
  width: 100%;
  /* border: 1px solid forestgreen; */
}
.purpleaa {
  width: 28%;
  height: 99%;
  /* height: 500px; */
  margin-left: 10px;
  /* border: 1px solid firebrick; */
  display: inline-block;
  vertical-align: top;
}
.purpleaa img {
  width: 100%;
  height: 49%;
}
.imagesbox {
  width: 100%;
  display: inline-block;
  height: 99%;
  /* border: 1px solid red; */
  /* background-color:#FFFFFF; */
  /* border: 1px solid fuchsia; */
}
.el-containers {
  width: 85%;
  margin: auto;
  height: 100%;
  /* display: inline-block; */
  /* background: #FFFFFF; */
  /* border: 1px solid red; */
}
.kuaixun {
  display: inline-block;
  vertical-align: top;
  /* height: 98%; */
  width: 99%;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  box-shadow: 0px 4px 6px 0px rgba(88, 90, 92, 0.27);
}
.fllll {
  height: 360px;
  /* border: 1px solid firebrick; */
  display: inline-block;
}
.more:hover {
  color: rgb(6 6 7);
}
.xjwplcont div {
  width: 100%;
}
.xjwplcont div::after {
  display: block;
  content: "";
  clear: both;
}
.xjwplcont img {
  width: 92px;
  height: 66px;
  float: right;
}
.xjwplcont div .lines-ellipsis {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
/* .xjwplcont div .lines-ellipsis{
  width: 50%;
  height: 0.485295rem;
  overflow: hidden;
  position: relative;
} */
/* .xjwplcont div .lines-ellipsis::after{
  content:". . .";
  font-weight:bold;
  position:absolute;
  background: linear-gradient(to right,rgba(255,255,255,0.4), rgba(255,255,255,1),rgba(255,255,255,1),rgba(255,255,255,1),rgba(255,255,255,1));
  bottom:0;
  right:0;
  padding:0 0px 1px 25px;
} */
.grid-content {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 42px;
  border-bottom: 1px solid #b8b8b8;
  margin-left: 20px;
  margin-right: 20px;
}
.aside_b {
  position: relative;
  top: -9.2px;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}
.aside_t {
  margin-bottom: 40px;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}
.Jxwz {
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dcdcdc;
}
.download {
  text-align: right;
  position: absolute;
  bottom: 190px;
  right: 0;
}
/* 下载二维码 */
.EWM {
  height: 102px;
  width: 102px;
  /* display: inline-block; */
  background-color: #c1c1c1;
}
.dis {
  /* float: left; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.select-tips {
  position: absolute;
  width: 160px;
  height: 211px;
  right: 100%;
  top: 0;
  margin-left: 15px;
  background: #fff;
  min-width: 126px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(185, 180, 180, 0.1);
  word-break: break-all;
  border: 3px solid #e5e5e5;
}
.select-tip-arrow {
  position: absolute;
  z-index: 2;
  top: 8%;
  right: -9px;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-left: 9px solid #e5e5e5;
  border-bottom: 9px solid transparent;
}
.select-tip-arrow em {
  width: 0;
  height: 0;
  position: absolute;
  right: 3px;
  top: -6px;
  border-top: 6px solid transparent;
  border-left: 7px solid #fff;
  border-bottom: 6px solid transparent;
}
.fill-content .fill-d input {
  border-width: 0 0 1px 0;
  outline: none;
}
.shouji {
  display: inline-block;
  width: 13px;
  height: 15px;
  max-width: 13px;
  max-height: 15px;
  /* background-color: red; */
  background-image: url("../../../assets/手机.png");
  background-size: 13px 15px;
  vertical-align: bottom;
  margin-top: 10px;
  /* border:1px solid red; */
}
.select-btn {
  width: 35px;
  height: 40px;
  background: #0084ff;
  display: inline-block;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #ffffff;
  margin-left: 10px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.el-timeline >>> .el-timeline-item__timestamp {
  font-size: 12px;
}
.el-timeline >>> .el-timeline-item__content {
  font-size: 13px;
  line-height: 21px;
}
/* 超过两行换省略号 */
.sheng_luehao {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
/* 快讯 */

.el-timeline-item__content {
  height: 35px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 21px;
}
::v-deep .el-timeline-item {
  padding-bottom: 20px;
}
.select-btn-grey {
  width: 35px;
  height: 40px;
  background: #979797;
  display: inline-block;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #ffffff;
  margin-left: 10px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid #ffffff;
  cursor: pointer;
}
</style>
